import * as React from 'react';

import useDimensions from "../services/useDimensions";
import Breakpoints from "../styles/Breakpoints";
import ScrollDown from "./ScrollDown";

const Header = () => {
    const size = useDimensions();

    return (
        <div
            style={{
                maxWidth: size.width <= Breakpoints.sm ? '95%' : '50%',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <div>
                <h1
                    style={{
                        fontSize: size.width <= Breakpoints.sm ? 50 : 60,
                    }}
                >
                    NowayRoleplay
                </h1>
                <p>
                    Een server waar ieder verhaal telt, de leus die de afgelopen 4,5 jaar centraal heeft gestaan binnen
                    NowayRoleplay. Het doel was om het voor elke speler naar z’n zin te maken en elke speler een
                    prettige en veilige weg te bieden om te ontsnappen aan de realiteit. Het is die drive en die
                    gedachte geweest die NowayRoleplay naar een ongekend niveau heeft getild, dit op een moment dat de
                    wereld in brand stond. Terwijl corona voor afstand zorgde, bracht NowayRoleplay de mensen dichter
                    bij elkaar. Het was een bizarre reis, maar daardoor geen minder mooie reis. We bereikten de top op
                    een moment dat NowayRoleplay zich geen betere community kon wensen, de warmte en gezelligheid die
                    iedereen toen der tijd met zich meebracht. Het voelde als thuiskomen.
                    <br/><br/>
                    Met meer dan 30,5 duizend unieke spelers en een totale speeltijd van ruim 66 jaar nemen we afscheid.
                    NowayRoleplay zal voor altijd in onze herinnering blijven en we hopen dat dit ook bij jou het geval
                    is. Ontzettend bedankt voor alle jaren die we er voor jou hebben mogen zijn en voor de talloze keren
                    dat je besloot om de server te joinen. We zullen je nooit vergeten en de dankbaarheid blijft. Hoe de
                    server technisch ook in elkaar zit, de spelers zorgen ervoor dat het bestaat.
                    <br/><br/>
                    Onwijs bedankt aan iedereen die ooit maar iets met NowayRoleplay te maken heeft gehad, we hebben er
                    met z’n allen een prachtige tijd van gemaakt. De herinneringen blijven, bekijk ze hieronder maar.
                    Het is een behoorlijke lijst, start maar vast met scrollen!
                </p>
            </div>

            {
                size.width > Breakpoints.sm && (
                    <div
                        style={{
                            height: 100,
                            position: 'relative',
                        }}
                    >
                        <ScrollDown
                            onClick={() => {
                                window.scrollTo({
                                    top: 896,
                                    behavior: 'auto',
                                });
                            }}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default Header;
