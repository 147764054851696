import * as React from 'react';

import '../styles/scrolldown.css';

const ScrollDown = ({onClick}) => (
    <div
        className="scroll-downs"
        onClick={onClick}
        style={{
            cursor: 'pointer',
        }}
    >
        <div className="mousey">
            <div className="scroller"></div>
        </div>
    </div>
);

export default ScrollDown;
