import * as React from 'react';

const YoutubeVideo = (props) => {
    const iframeRef = React.useRef();

    const outsideClick = event => {
        if (!iframeRef.current.contains(event.target)) {
            props.onClose();
        }
    }

    return (
        <div
            onClick={outsideClick}
            style={{
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
                position: 'fixed',
                zIndex: 1,
                padding: 100,
                backgroundColor: 'rgba(0, 0, 0, .8)'
            }}
        >
            <div
                style={{
                    color: 'white',
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    fontSize: 40,
                    fontWeight: 'bold',
                    opacity: .2,
                }}
            >
                ×
            </div>
            <iframe
                ref={iframeRef}
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${props.id}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default YoutubeVideo;
