import * as React from 'react';
import {ReactFitty} from "react-fitty";

import paperImage from '../images/paper.png';
import Breakpoints from "../styles/Breakpoints";
import useDimensions from "../services/useDimensions";

const Paper = ({ note, widthPercentage }) => {
    const size = useDimensions();

    return (
        <div
            style={{
                backgroundImage: `url(${paperImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: 404,
                width: 481 * (widthPercentage ?? 1),
                paddingTop: 90,
                paddingLeft: 90,
            }}
        >
            <div
                className="handwriting"
                style={{
                    width: (size.width <= Breakpoints.sm ? 320 : 342) * (widthPercentage ?? 1),
                    color: '#000000',
                    fontSize: '100%',
                    lineHeight: 1.4,
                }}
            >
                <ReactFitty
                    minSize={10}
                    wrapText
                >
                    {note}
                </ReactFitty>
            </div>
        </div>
    )
}

export default Paper;
