import * as React from 'react';
import Play from "../svg/Play";

const Polaroid = (props) => {
    return (
        <>
            <div
                style={{
                    width: 481 * (props.widthPercentage ?? 1),
                    paddingTop: 16,
                    paddingLeft: 16,
                    paddingRight: 16,
                    backgroundColor: '#ffffff',
                    position: 'relative',
                }}
                onClick={props.onClick}
            >
                {
                    props.playIcon && (
                        <div
                            style={{
                                position: 'absolute',
                                width: 'calc(100% - 32px)',
                                height: 'calc(100% - 16px - 83px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, .5)'
                            }}
                        >
                            <Play
                                style={{
                                    width: 75,
                                    height: 75,
                                }}
                            />
                        </div>
                    )
                }
                <img
                    src={props.src}
                    alt={props.alt}
                    loading="lazy"
                    style={{
                        height: 'auto',
                        width: '100%',
                    }}
                />
                <div
                    className="marker"
                    style={{
                        fontSize: '1.8rem',
                        textAlign: 'center',
                        lineHeight: '2cm',
                        color: '#000000',
                        height: 83,
                    }}
                >
                    {props.caption}
                </div>
            </div>
        </>
    )
}

export default Polaroid;
