const Breakpoints = {
    fd: 330,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1100,
    xl: 1400,
    xxl: 1440,
    max: 1800,
};

export default Breakpoints;
