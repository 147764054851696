import * as React from 'react';
import Polaroid from "./Polaroid";
import Breakpoints from "../styles/Breakpoints";
import useDimensions from "../services/useDimensions";
import Paper from "./Paper";
import axios from "axios";

const RotationWrapper = ({ children }) => {
    const size = useDimensions();
    const [rotate] = React.useState(`rotate(${Math.floor(Math.random() * (2 - -2 + 1) + -2)}deg)`);

    return (
        <div
            style={{
                marginLeft: size.width <= Breakpoints.sm ? 0 : 30,
                marginRight: size.width <= Breakpoints.sm ? 0 : 30,
                marginBottom: 30,
                transform: rotate,
            }}
        >
            {children}
        </div>
    );
}

const Gallery = (props) => {
    const size = useDimensions();
    const [, setFullOpen] = props.useFullOpen;
    const [, setYoutubeFullOpen] = props.useFullYoutubeOpen;
    const bottomRef = React.useRef();

    const possiblePrimeNumbers = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97];

    const [collections, setCollections] = React.useState([]);
    const [primeNumber] = React.useState(localStorage.getItem('primeNumber') ?? possiblePrimeNumbers[Math.floor(Math.random() * possiblePrimeNumbers.length)]);
    const [page, setPage] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [nextPage, setNextPage] = React.useState(true);

    let loadingPage = false;

    React.useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/content.php', {
            params: {
                prime_number: primeNumber,
                page,
            }
        })
            .then(({ data }) => {
                setCollections(prev => [...prev, ...data]);
                setLoading(false);
                /* eslint-disable-next-line react-hooks/exhaustive-deps */
                loadingPage = false;
                if (data.length < 50) setNextPage(false);
            })
            .catch(() => {});
    }, [page]);

    React.useEffect(() => {
        const scrollHandler = () => {
            if (bottomRef.current) {
                const whenToLoad = bottomRef.current.offsetTop - 1000;

                if (document.documentElement.scrollTop >= whenToLoad && !loading && nextPage && !loadingPage) {
                    /* eslint-disable-next-line react-hooks/exhaustive-deps */
                    loadingPage = true;
                    setLoading(true);
                    setPage(prev => prev + 1);
                }
            }
        };

        document.addEventListener('scroll', scrollHandler);

        return () => document.removeEventListener('scroll', scrollHandler);
    }, [loading, page]);

    if (collections.length === 0) return null;

    return (
        <div
            style={{
                maxWidth: size.width <= Breakpoints.sm ? '95%' : '90%',
                padding: 10,
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
            }}
        >
            {
                collections.map(col => {

                   if (col.type === 'image') {
                       const firstImage = col.items[0];

                       return (
                           <RotationWrapper
                               key={col.id}
                           >
                               <Polaroid
                                   src={firstImage.small_url}
                                   alt={firstImage.credits ?? 'Polaroid'}
                                   widthPercentage={size.width <= Breakpoints.sm ? .7 : 1}
                                   caption={col.text}
                                   onClick={() => {
                                       setFullOpen(
                                           col.items.map(i => i.normal_url),
                                       );
                                   }}
                               />
                            </RotationWrapper>
                       );
                   }

                   if (col.type === 'note') {
                       return (
                           <RotationWrapper
                               key={col.id}
                           >
                               <Paper
                                   note={col.text}
                                   widthPercentage={size.width <= Breakpoints.sm ? .7 : 1}
                               />
                           </RotationWrapper>
                       );
                   }

                   if (col.type === 'video') {
                       const firstSource = col.items[0];

                       if (firstSource.type === 'youtube') {
                           return (
                               <RotationWrapper>
                                   <Polaroid
                                       src={`http://i3.ytimg.com/vi/${firstSource.normal_url}/maxresdefault.jpg`}
                                       widthPercentage={size.width <= Breakpoints.sm ? .7 : 1}
                                       onClick={() => {
                                           setYoutubeFullOpen(
                                               firstSource.normal_url,
                                           );
                                       }}
                                       playIcon
                                   />
                               </RotationWrapper>
                           );
                       }
                   }


                   return null;
                })
            }
            <div
                ref={bottomRef}
            />
        </div>
    );
}

export default Gallery;
