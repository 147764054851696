import * as React from 'react';
import Header from "./Components/Header";
import Gallery from "./Components/Gallery";
import ImageViewer from "react-simple-image-viewer";
import YoutubeVideo from "./Components/YoutubeVideo";
import useDimensions from "./services/useDimensions";
import Breakpoints from "./styles/Breakpoints";

const App = () => {
    const size = useDimensions();

    const [fullOpen, setFullOpen] = React.useState(null);
    const [fullYoutubeOpen, setFullYoutubeOpen] = React.useState(null);

    React.useEffect(() => {
        document.body.style.overflow = fullYoutubeOpen ? 'hidden' : 'auto';
    }, [fullYoutubeOpen]);

    return (
        <>
            {
                fullYoutubeOpen && (
                    <YoutubeVideo
                        id={fullYoutubeOpen}
                        onClose={() => setFullYoutubeOpen(null)}
                    />
                )
            }
            {fullOpen && (
                <ImageViewer
                    src={ fullOpen }
                    currentIndex={ 0 }
                    disableScroll={ true }
                    closeOnClickOutside={ true }
                    onClose={ () => setFullOpen(false) }
                />
            )}
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 50,
                    paddingLeft: 10,
                    paddingRight: 10,
                }}
            >
                <Header/>
            </div>
            <div
                style={{
                    marginTop: size.width <= Breakpoints.sm ? 300 : 200,
                }}
            >
                <Gallery
                    useFullOpen={[ fullOpen, setFullOpen ]}
                    useFullYoutubeOpen={[fullYoutubeOpen, setFullYoutubeOpen]}
                />
            </div>
        </div>
    </>
    );
}

export default App;
